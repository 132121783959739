<script setup>
import { useApi } from '@apility/ticketing'

const { busy } = useApi()
</script>

<template>
    <div v-if="busy" class="d-lg-none position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-white opacity-75" style="z-index: 1000">
        <span class="h1">
            <font-awesome-icon icon="spinner" spin />
        </span>
    </div>
    <slot></slot>
</template>