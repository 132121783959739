<script setup>
import { computed, watch } from "vue";
import { useDiscounts } from "@apility/ticketing/src/composables/discounts";
import { useCart } from "@apility/ticketing/src/composables/cart";

const { discounts, discount } = useDiscounts();
const { cart } = useCart();

const availableDiscounts = computed(() => {
  return discounts.value.filter((discount) => {
    if (discount.options.minimumQuantity) {
      const quantity = cart.value
        .filter((item) => discount.products.includes(item.id))
        .reduce((total, item) => total + item.quantity, 0);
      return quantity >= discount.options.minimumQuantity;
    }

    return true;
  });
});

const bestDiscount = computed(() => {
  const length = availableDiscounts.value.length;
  return length ? availableDiscounts.value[length - 1] : null;
});

watch(
  bestDiscount,
  (bestDiscount) => {
    if (bestDiscount) {
      discount.value = bestDiscount.id;
    } else {
      discount.value = null;
    }
  },
  { immediate: true }
);
</script>
