<script setup>
    import { defineProps, defineEmits, computed, ref, watch } from 'vue'
    import PackageProductOption from './PackageProductOption.vue'

    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        modelValue: {
            type: Object,
            default: () => ref([])
        },

        product: {
            type: Object,
            required: true
        }
    })



    const state = ref([...props.modelValue.value])

    watch(state, (newState) =>{
        emit('update:modelValue', state)
    }, { deep: true })

    const getSubproductState = (product) => {
        return state.value.filter(({ packagePosition}) => product.options.find(option => option.packagePosition === packagePosition))
    }

    const setSubproductState = (product, value) => {
        state.value = [
            ...state.value.filter(({ packagePosition }) => !product.options.find(option => option.packagePosition === packagePosition)),
            ...value
        ]
    }

    const computedSubProducts = computed(() => {
        const content = props.product.options.packageContent
        
        return content.filter((product, i, products) => 
            products.findIndex(p => p.personTypeId === product.personTypeId) === i
        ).map(product => {
            const options = content.filter(p => p.personTypeId === product.personTypeId)
            const quantity = options.reduce((acc, option) => acc + (props.modelValue.value.find(mv => option.packagePosition === mv.packagePosition)?.quantity ?? 0), 0)

            const computedProduct = {
                ...product,
                quantity,
                options
            }

            return computedProduct
        })
    })
</script>

<template>
    <div class="card border">
        <div class="card-body">
            <PackageProductOption
                v-for="(product, index) in computedSubProducts"
                :key="product.personTypeId"
                :product="product"
                :model-value="getSubproductState(product)"
                @update:model-value="event => setSubproductState(product, event)"
            />
        </div>
    </div>
</template>