<script setup>
import QuantityPicker from "@apility/ticketing/src/components/QuantityPicker.vue";
import { useCart, useCartProduct, useEvents } from "@apility/ticketing";
import { defineProps, computed, ref, watch, nextTick, reactive } from "vue";
import PackageProductOptions from "./PackageProductOptions.vue";
import CurrencyStringRange from "./CurrencyStringRange.vue";

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },

  fromLabel: {
    type: String,
    default: "From",
    required: true,
  },

  toLabel: {
    type: String,
    default: "To",
    required: true,
  },
});

const { emitEvent } = useEvents();

const { cart } = useCart();
const { canIncrement, canDecrement } = useCartProduct(props.product.id);
const cartItem = computed(() =>
  cart.value.find((product) => product.id === props.product.id)
);

watch(cartItem, (item) => {
  if (!item || item.options.populatedFromOrder) {
    mutateCart();
  }
});

const max = ref(99);
const state = ref(
  (cartItem ? cartItem.value?.options?.packagePositions ?? [] : []).map(
    (value) => ref(value)
  )
);
const quantity = ref(
  cart.value
    .filter((product) => product.id === props.product.id)
    .reduce((quantity, item) => quantity + item.quantity, 0)
);

const mutateCart = () => {
  const existingItemInCart = cart.value.find(
    (product) => product.id === props.product.id
  );
  const newQuantity = Math.max(0, Math.min(quantity.value, max.value));
  const mutatedCart = cart.value.filter(
    (product) => product.id !== props.product.id
  );

  if (newQuantity > 0) {
    const total = state.value.reduce((acc, options) => {
      return (
        acc +
        options.value.reduce((acc, option) => {
          const quantity = option.quantity;
          const content = props.product.options.packageContent.find(
            (p) => p.packagePosition === option.packagePosition
          );

          if (content) {
            return acc + quantity * content.price;
          }

          return acc;
        }, 0)
      );
    }, 0);

    const cartItem = {
      id: props.product.id,
      quantity: newQuantity,
      options: {
        packagePositions: [
          ...state.value.map((value) =>
            JSON.parse(JSON.stringify(value.value))
          ),
        ],
      },
    };

    if (total > 0) {
      cartItem.total = total;

      if (
        existingItemInCart &&
        existingItemInCart.total !== undefined &&
        existingItemInCart.total != total
      ) {
        if (existingItemInCart.total == 0) {
          if (!existingItemInCart.options.populatedFromOrder) {
            emitEvent("addToCart", {
              id: props.product.id,
              name: props.product.name,
              quantity: newQuantity,
              price: total / newQuantity,
            });
          }
        } else {
          if (total > existingItemInCart.total) {
            emitEvent("updateCart", {
              id: props.product.id,
              name: props.product.name,
              quantity: newQuantity,
              price: total / newQuantity,
            });
          } else {
            emitEvent("updateCart", {
              id: props.product.id,
              name: props.product.name,
              quantity: newQuantity,
              price: total / newQuantity,
            });
          }
        }
      }
    }

    mutatedCart.push(cartItem);
  }

  cart.value = [...mutatedCart];
  quantity.value = newQuantity;
};

watch(quantity, async (newQuantity, previousQuantity) => {
  quantity.value = newQuantity;

  if (state.value.length > newQuantity) {
    state.value = state.value.slice(0, newQuantity);
  }

  if (newQuantity === 0 && previousQuantity > 0) {
    emitEvent("removeFromCart", {
      id: props.product.id,
      name: props.product.name,
    });
  }

  mutateCart();
});

const getStateForPackageProductOptions = (index) => {
  if (!state.value.length) {
    state.value = [ref([])];
  }

  if (!state.value[index]) {
    const newState = [...state.value];

    newState[index] = ref([]);
    state.value = newState;
  }

  return state.value[index];
};

const setStatesForPackageProductOptions = (index, value) => {
  const newState = [...state.value];

  newState[index] = value;
  state.value = newState;

  nextTick(() => {
    mutateCart();
  });
};

const minimumPrice = computed(() =>
  props.product.options.packageContent.reduce(
    (acc, option) => acc + option.minimumQuantity * option.price,
    0
  )
);
const maximumPrice = computed(() =>
  props.product.options.packageContent.reduce(
    (acc, option) => acc + option.maximumQuantity * option.price,
    0
  )
);

const isLastProductFullfilled = computed(() => {
  if (!quantity.value) {
    return true;
  }

  const latest = state.value[quantity.value - 1];

  if (latest && latest.value && latest.value.length) {
    const consumed = Math.max(
      0,
      latest.value.reduce((acc, option) => acc + option.quantity, 0)
    );
    const minimumQuantity = props.product.options.packageContent.reduce(
      (acc, option) => acc + option.minimumQuantity,
      0
    );
    const maximumQuantity = props.product.options.packageContent.reduce(
      (acc, option) => acc + option.maximumQuantity,
      0
    );
    return consumed >= minimumQuantity && consumed <= maximumQuantity;
  }

  return true;
});

const computedCanIncrement = computed(() => {
  return (
    canIncrement.value && (!state.value.length || isLastProductFullfilled.value)
  );
});
</script>

<template>
  <storefront-widget>
    <div class="storefront__product mb-3">
      <div class="storefront__product__details">
        <div class="storefront__product__details__name">
          <strong>{{ product.name }}</strong>
        </div>
        <div
          v-if="product.description"
          class="storefront__product__details__description"
        >
          {{ product.description }}
        </div>
        <div
          v-if="product.description"
          class="storefront__product__details__price"
        >
          <CurrencyStringRange
            :from-label="fromLabel"
            :to-label="toLabel"
            :min="minimumPrice"
            :max="maximumPrice"
          />
        </div>
      </div>
      <div class="storefront__product__quantity">
        <QuantityPicker
          v-model="quantity"
          :max="max"
          :can-increment="computedCanIncrement"
          :can-decrement="canDecrement"
        />
      </div>
    </div>
    <div class="d-flex flex-column gap-2">
      <div v-for="index in quantity" :key="index">
        <PackageProductOptions
          :product="product"
          :model-value="getStateForPackageProductOptions(index - 1)"
          @update:modelValue="
            setStatesForPackageProductOptions(index - 1, $event)
          "
        />
      </div>
    </div>
  </storefront-widget>
</template>
