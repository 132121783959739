<script setup>
import { computed } from 'vue'
import StorefrontWidget from '@apility/ticketing/src/components/StorefrontWidget.vue'
import { useDiscounts } from '@apility/ticketing/src/composables/discounts'
import { useCart } from '@apility/ticketing/src/composables/cart'
const { discounts, discount } = useDiscounts()
const { cart } = useCart()

const availableDiscounts = computed(() => {
    return discounts.value.filter(discount => {
        if (discount.options.minimumQuantity) {
            const quantity = cart.value.filter(item => discount.products.includes(item.id)).reduce((total, item) => total + item.quantity, 0)
            return quantity >= discount.options.minimumQuantity
        }

        return true
    })
})
</script>

<template>
    <StorefrontWidget v-if="availableDiscounts.length">
        <template #header>
            <slot name="header">
                Discount
            </slot>
        </template>
        <select class="form-select" aria-label="Default select example" v-model="discount">
            <option :value="null">
                <slot name="no-discount-selected-label">
                    No discount
                </slot>
            </option>
            <option v-for="discount in availableDiscounts" :value="discount.id">
                {{ discount.label }}
            </option>
        </select>
    </StorefrontWidget>
</template>