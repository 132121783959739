<template>
    <div class="d-none"></div>
</template>

<script setup>
    import { nextTick, onMounted } from 'vue'
    import { useEvents } from '@apility/ticketing'

    const props = defineProps({
        name: {
            type: String,
            required: true,
        },

        value: {
            type: Object,
            default: () => ({}),
        },
    })

    const { emitEvent } = useEvents()

    onMounted(() => {
        nextTick(() => {
            emitEvent(props.name, props.value)
        })
    })
</script>