<script setup>
    import { defineProps, computed, ref, watch, defineEmits, nextTick } from 'vue'
    import QuantityPicker from '@apility/ticketing/src/components/QuantityPicker.vue'

    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        modelValue: {
            type: Array,
            default: () => []
        },

        product: {
            type: Object,
            required: true
        }
    })

    const min = computed(() => {
        return props.product.options
            .reduce((acc, option) => acc + option.minimumQuantity, 0)
    })

    const max = computed(() => {
        return props.product.options
            .reduce((acc, option) => acc + option.maximumQuantity, 0)
    })

    const canIncrement = computed(() => {
        return quantity.value < max.value
    })

    const canDecrement = computed(() => {
        return quantity.value > min.value
    })

    const quantity = ref(props.modelValue.reduce((acc, option) => acc + option.quantity, 0))

    watch(quantity, (value) => {
        if (value < min.value) {
            quantity.value = min.value
            return
        }

        if (value > max.value) {
            quantity.value = max.value
            return
        }

        const fixedQuantities = props.product.options
            .filter(option => option.fixedQuantity)

        let payload = [
            ...fixedQuantities.map(option => ({
                packagePosition: option.packagePosition,
                quantity: option.quantity
            }))
        ]

        const fixedQuantityTotal = payload.reduce((acc, option) => acc + option.quantity, 0)

        if ((value - fixedQuantityTotal) > 0) {
            const dynamicOption = props.product.options
                .filter(option => !option.fixedQuantity)
                .find(option => option.minimumQuantity <= value - fixedQuantityTotal && option.maximumQuantity >= value - fixedQuantityTotal)
                
                if (dynamicOption) {
                    payload = [
                        ...payload,
                        {
                            packagePosition: dynamicOption.packagePosition,
                            quantity: value - fixedQuantityTotal
                        }
                    ]
                }
        }

        emit('update:modelValue', payload)
    }, { immediate: true })
</script>

<template>
    <div class="storefront__product mb-3">
        <div class="storefront__product__details">
            <div class="storefront__product__details__name">
                <strong>{{ product.name }}</strong>
            </div>
            <div class="storefront__product__details__description">
                {{ product.description }}
            </div>
        </div>
        <div class="storefront__product__quantity">
            <div class="storefront__quantity-picker storefront__quantity-picker--sm">
                <quantity-picker
                    :disabled="min === max"
                    :min="min"
                    :max="max"
                    :can-increment="canIncrement"
                    :can-decrement="canDecrement"
                    v-model="quantity"
                />
            </div>
        </div>
    </div>
</template>