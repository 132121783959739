import { createApp } from 'vue'

import Ticketing, { useEvents } from '@apility/ticketing'
import FontAwesomeIcon from './components/FontawesomeIcon'
import OffcanvasWidget from './components/OffcanvasWidget'
import DiscountPicker from './components/DiscountPicker'
import AutomaticDiscountPicker from './components/AutomaticDiscountPicker'
import PackageProductItem from './components/PackageProductItem'
import CartObserver from './components/CartObserver'
import BusyWrapper from './components/BusyWrapper'
import EventLogger from './components/EventLogger'
import GtagManager from './components/GtagManager'
import VoucherRedemption from './components/VoucherRedemption'

document.querySelectorAll('.ticketing')
    .forEach(container => createApp({
        data: () => ({
            showCheckout: false,
        })
    })
        .component('OffcanvasWidget', OffcanvasWidget)
        .component('FontAwesomeIcon', FontAwesomeIcon)
        .component('DiscountPicker', DiscountPicker)
        .component('AutomaticDiscountPicker', AutomaticDiscountPicker)
        .component('PackageProductItem', PackageProductItem)
        .component('CartObserver', CartObserver)
        .component('BusyWrapper', BusyWrapper)
        .component('EventLogger', EventLogger)
        .component('GtagManager', GtagManager)
        .component('VoucherRedemption', VoucherRedemption)
        .directive('focus', (el) => (el.focus()))
        .use(Ticketing)
        .mount(container)
    )
