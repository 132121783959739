<script setup>
import { useApi, useCartProduct } from '@apility/ticketing'
import { useDiscounts } from '@apility/ticketing/src/composables/discounts'
import { ref } from 'vue'

const props = defineProps({
  productId: Number,
  discountId: Number,
})

const { quantity } = useCartProduct(props.productId, ref(1));
quantity.value = 1

const { allDiscounts, discount, selectedDiscount } = useDiscounts()

const discountObject = { id: props.discountId }

allDiscounts.value = [discountObject]
discount.value = discountObject.id

const { submitCart } = useApi()

const showCheckout = ref(false)

/** @type {Ref<'idle' | 'loading'>} */
const submittingCartState = ref('idle')

const onButtonClick = async () => {
  if (submittingCartState.value === 'loading') {
    return
  }

  submittingCartState.value = 'loading'

  try {
    await submitCart()

    showCheckout.value = true
  } finally {
    submittingCartState.value = 'idle'
  }
}
</script>

<template>
  <button
    @click="onButtonClick"
    class="btn btn-lg btn-primary"
  >
    <slot name="button-label" />

    <font-awesome-icon
      v-if="submittingCartState === 'loading'"
      icon="spinner"
      spin
    ></font-awesome-icon>
  </button>

  <offcanvas-widget
    v-model:show="showCheckout"
  >
    <template #title>
      <h5>
        <slot name="checkout-title" />
      </h5>
    </template>

    <checkout-form>
      <template v-slot="{ busy }">
        <button
          class="storefront__checkout-form__button"
          type="submit"
          :disabled="busy"
        >
          <font-awesome-icon
            v-if="busy"
            icon="spinner"
            spin
          ></font-awesome-icon>

          <span v-else>
            <slot name="continue-button-label" />
          </span>
        </button>
      </template>
    </checkout-form>
  </offcanvas-widget>

</template>

<style scoped>
</style>
