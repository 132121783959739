<script setup>
    import { watch, nextTick } from 'vue'
    import { useProducts, useCart, useSlots, useDate } from '@apility/ticketing'
    
    const { cart } = useCart()
    const { products } = useProducts()
    const { slot, slots } = useSlots()
    const { date, formattedDate } = useDate()

    const observeSlots = (slots) => {
        const availableSlots = slots.filter(slot => !slot.options.disabled)
        if (availableSlots.length) {
            if (!availableSlots.find(item => item.id === slot.value)) {
                slot.value = availableSlots.find(slot => slot).id
            }
        } else {
            slot.value = null
        }
    }

    function hookLocalizedLinks (date) {
        const baseUrl = [window.location.protocol, '//', window.location.host, window.location.pathname].join('')
        document.querySelectorAll('ul[aria-labelledby="navbarLocales"] li a').forEach(link => {
            if (!link.originalHref) {
                link.originalHref = link.href
            }

            if (date) {
                link.href = `${link.originalHref}?date=${date}`
            } else {
                link.href = link.originalHref
            }
        })

        if (date) {
            window.history.replaceState({}, '', `${baseUrl}?date=${date}`)
        } else {
            window.history.replaceState({}, '', baseUrl)
        }
    }

    watch(slots, (newSlots) => observeSlots(newSlots), { deep: true, immediate: true })
    watch(date, () => observeSlots(slots.value), { deep: true, immediate: true })
    watch(date, () => {
        if (formattedDate.value !== new Date().toJSON().substring(0,10)) {   
            hookLocalizedLinks(formattedDate.value)
        } else {
            hookLocalizedLinks(null)
        }
    })

    if (formattedDate.value !== new Date().toJSON().substring(0,10)) {   
        hookLocalizedLinks(formattedDate.value)
    } else {
        hookLocalizedLinks(null)
    }

    const watchCartForProduct = (productId, callback) => {
        watch(cart, (cart, previousCart) => {
            const product = cart.find(product => product.id === productId)
            const previousProduct = previousCart ? previousCart.find(product => product.id === productId) : null
            
            if (product && (!previousProduct || previousProduct.quantity !== product.quantity)) {
                return callback(product.quantity)
            }
            
            if (previousProduct && !product) {
                callback(0)
            }
        }, { deep: true, immediate: true })
    }

    const addProductToCart = (product, quantity) => {
        const productExistsInCart = cart.value.find(item => item.id === product.id)
        const quantityInCartDiffers = productExistsInCart && productExistsInCart.quantity !== quantity
        const shouldAddToCart = !productExistsInCart || quantityInCartDiffers

        if (shouldAddToCart) {
            nextTick(() => {
                const newCart = [
                    ...cart.value.filter(item => item.id !== product.id),
                    {
                        id: product.id,
                        quantity: quantity,
                        options: product.options ?? {}
                    }
                ]

                cart.value = newCart
            })
        }
    }

    const removeProductFromCart = (product) => {
        nextTick(() => {
            if (cart.value.find(item => item.id === product.id)) {
                cart.value = cart.value.filter(item => item.id !== product.id)
            }
        })
    }

    watch(products, (products) => {
        products.forEach(product => {
            let watching = true

            if (product.options.automaticallyAddWhenProductsAdded) {
                product.options.automaticallyAddWhenProductsAdded.forEach(productId => {
                    if (cart.value?.length ?? 0 > 0) {
                        watching = false
                        if (cart.value.find(item => item.id === product.id) && !cart.value.find(item => item.di === productId)) {
                            removeProductFromCart(product)
                        }

                        watching = true
                    }


                    watchCartForProduct(productId, (quantity) => {
                        if (watching && quantity > 0) {
                            addProductToCart(product, Math.max(1, product.options.automaticallyAddMaxSameAsProductsAdded ? quantity : 1))
                            watching = true
                        }
                    })
                })
            }

            if (product.options.automaticallyRemoveWhenProductsRemoved) {
                product.options.automaticallyRemoveWhenProductsRemoved.forEach(productId => {
                    watchCartForProduct(productId, (quantity) => {
                        if (watching && quantity <= 0) {
                            removeProductFromCart(product)
                            watching = true
                        }
                    })
                })
            }
        });
    }, { deep: true, immediate: true })
</script>

<template></template>