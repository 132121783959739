<template>
    <div class="d-none"></div>
</template>

<script setup>
    import { useCart, useEvents, useProducts } from '@apility/ticketing'
    import { nextTick, watch } from 'vue'
    
    const { onEvent } = useEvents()

    let internalCommerceState = []

    let previousTotal = 0
    const { cart, total } = useCart()
    const { products } = useProducts()

    cart.value.forEach(element => {
        internalCommerceState.push({
            id: element.id,
            quantity: element.quantity,
            total: element.quantity * element.price
        })
    })

    const gtagPush = (payload) => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(payload)
    }

    const mutateCart = options => {
        nextTick(() => {
            const existingItemInCart = internalCommerceState.find(product => product.id === options.id)

            if (!existingItemInCart) {
                internalCommerceState.push({
                    id: options.id,
                    quantity: options.quantity,
                    total: options.price * options.quantity
                })
            }

            let existingQuantity = 0
            let existingTotal = 0

            if (existingItemInCart) {
                existingQuantity = existingItemInCart.quantity
                existingTotal = existingItemInCart.total
            }

            const quantityDiff = options.quantity - existingQuantity

            if (quantityDiff > 0) {
                gtagPush({
                    event: 'add_to_cart',
                    ecommerce: {
                        currency: 'NOK',
                        value: options.price * quantityDiff,
                        items: [
                            {
                                item_id: String(options.id),
                                item_name: options.name,
                                quantity: options.quantity - existingQuantity,
                            }
                        ]
                    }
                })
            } else {
                gtagPush({
                    event: 'remove_from_cart',
                    ecommerce: {
                        currency: 'NOK',
                        value: options.price * Math.abs(quantityDiff),
                        items: [
                            {
                                item_id: String(options.id),
                                item_name: options.name,
                                quantity: Math.abs(options.quantity - existingQuantity),
                            }
                        ]
                    }
                })
            }

            internalCommerceState = [
                ...internalCommerceState.filter(product => product.id !== options.id),
                {
                    id: options.id,
                    quantity: options.quantity,
                    total: options.price * options.quantity
                }
            ]
        })
    }

    onEvent('addToCart', mutateCart)
    onEvent('updateCart', mutateCart)

    onEvent('removeFromCart', options => {
        nextTick(() => {
            const existingItemInCart = internalCommerceState.find(product => product.id === options.id)

            if (existingItemInCart) {
                gtagPush({
                    event: 'remove_from_cart',
                    ecommerce: {
                        currency: 'NOK',
                        value: existingItemInCart.total,
                        items: [
                            {
                                item_id: String(options.id),
                                item_name: options.name,
                                quantity: existingItemInCart.quantity,
                            }
                        ]
                    }
                })

                internalCommerceState = internalCommerceState.filter(product => product.id !== options.id)
            }
        })
    })

    onEvent('beginCheckout', options => {
        nextTick(() => {
            if (total.value > 0) {
                gtagPush({
                    event: 'begin_checkout',
                    ecommerce: {
                        currency: 'NOK',
                        value: total.value ? total.value : previousTotal,
                        items: cart.value.map(item => {
                            const product = products.value.find(product => product.id === item.id)

                            return {
                                item_id: String(product.id),
                                item_name: product.name,
                                quantity: item.quantity,
                            }
                        })
                    },
                })
            }
        })
    })

    onEvent('viewCart', options => {
        nextTick(() => {
            const value = total.value ? total.value : previousTotal
            if (value) {
                gtagPush({
                    event: 'view_cart',
                    currency: 'NOK',
                    value: total.value ? total.value : previousTotal,
                    items: cart.value.map(item => {
                        const product = products.value.find(product => product.id === item.id)

                        return {
                            item_id: String(product.id),
                            item_name: product.name,
                            quantity: item.quantity,
                        }
                    })
                })
            }
        })
    })
</script>